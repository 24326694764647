import React from "react";
import { useState } from "react";

import './App.css';

import Page1 from './pages/page1/page1.js';
import Page2 from './pages/page2/page2.js';
import Page3 from './pages/page3/page3.js';
import Page4 from './pages/page4/page4.js';

const App = () => {
  const sun = (
    <svg fill="#ffffff" viewBox="0 0 24 24"><path d="M12 9c1.65 0 3 1.35 3 3s-1.35 3-3 3-3-1.35-3-3 1.35-3 3-3m0-2c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5M2 13h2c.55 0 1-.45 1-1s-.45-1-1-1H2c-.55 0-1 .45-1 1s.45 1 1 1m18 0h2c.55 0 1-.45 1-1s-.45-1-1-1h-2c-.55 0-1 .45-1 1s.45 1 1 1M11 2v2c0 .55.45 1 1 1s1-.45 1-1V2c0-.55-.45-1-1-1s-1 .45-1 1m0 18v2c0 .55.45 1 1 1s1-.45 1-1v-2c0-.55-.45-1-1-1s-1 .45-1 1M5.99 4.58c-.39-.39-1.03-.39-1.41 0-.39.39-.39 1.03 0 1.41l1.06 1.06c.39.39 1.03.39 1.41 0s.39-1.03 0-1.41zm12.37 12.37c-.39-.39-1.03-.39-1.41 0-.39.39-.39 1.03 0 1.41l1.06 1.06c.39.39 1.03.39 1.41 0 .39-.39.39-1.03 0-1.41zm1.06-10.96c.39-.39.39-1.03 0-1.41-.39-.39-1.03-.39-1.41 0l-1.06 1.06c-.39.39-.39 1.03 0 1.41s1.03.39 1.41 0zM7.05 18.36c.39-.39.39-1.03 0-1.41-.39-.39-1.03-.39-1.41 0l-1.06 1.06c-.39.39-.39 1.03 0 1.41s1.03.39 1.41 0z"></path></svg>
  );
  const moon = (
    <svg viewBox="0 0 24 24"><path d="M9.37 5.51c-.18.64-.27 1.31-.27 1.99 0 4.08 3.32 7.4 7.4 7.4.68 0 1.35-.09 1.99-.27C17.45 17.19 14.93 19 12 19c-3.86 0-7-3.14-7-7 0-2.93 1.81-5.45 4.37-6.49M12 3c-4.97 0-9 4.03-9 9s4.03 9 9 9 9-4.03 9-9c0-.46-.04-.92-.1-1.36-.98 1.37-2.58 2.26-4.4 2.26-2.98 0-5.4-2.42-5.4-5.4 0-1.81.89-3.42 2.26-4.4-.44-.06-.9-.1-1.36-.1"></path></svg>
  );
  const[darkMode,setDarkMode] = useState(true)
    const toggleDarkMode = () => {
      
      setDarkMode(prevMode => !prevMode);
    };
  
  return (
    <div className="App">
    <link rel="canonical" href="https://www.janstuers.de"/>
      <div className={darkMode ? 'dark-mode' : 'light-mode'}>
        <button className="changeApperanceButton" onClick={toggleDarkMode}>
          {darkMode ? sun : moon}
        </button>
        <section id="home">
          <Page1/>
        </section> 
        <section id="about">
          <Page2/>
        </section>
        <section id="skills">
          <Page3/>
        </section>
        <section id="contact">
          <Page4/>
        </section>
        </div>
    </div>
  );
}

export default App;

/*

*/
