import React, { useEffect, useState, useRef } from "react";
import {FaBars, FaTimes} from "react-icons/fa";
import { Link } from "react-scroll";

import './NavBar.css';

const NavBar = () => {
    const navRef= useRef();
    const showNavbar = () => {
        navRef.current.classList.toggle("responsive_nav");
    }

    const [navBackDrop, setNavBackDrop] = useState("none");
    const scrollEvent = () => {
        window.scrollY > 20 ? setNavBackDrop("blur(10px)"):setNavBackDrop("none");
    }
    

    useEffect(() => {
        window.addEventListener("scroll",scrollEvent);
        return () => {
            window.removeEventListener("scroll", scrollEvent);
        };
    }, []);
    
    return(
        <header className='NavBar' style={{backdropFilter:navBackDrop}}>
            <nav ref={navRef}>
                <Link activeClass="active" smooth spy to="home" onClick={showNavbar}>Home</Link>
                <Link activeClass="active" smooth spy to="about" onClick={showNavbar}>About&nbsp;me</Link>
                <Link activeClass="active" smooth spy to="skills" onClick={showNavbar}>Skills</Link>
                <Link activeClass="active" smooth spy to="contact" onClick={showNavbar}>Contact</Link>
                
                
                <button className="nav-btn nav-close-btn" onClick={showNavbar}>
                    <FaTimes/>
                </button>
            </nav>
            <button className="nav-btn" onClick={showNavbar}>
                <FaBars/>
            </button>
        </header>
    )
}

export default NavBar;