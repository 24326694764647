import React from 'react';

import './AltContact.css';
import { IconContext } from "react-icons";
import {FaLinkedin, FaInstagram} from 'react-icons/fa';

const AltContact = (props) => {
  return(
    <footer className = "footer border-floating">
        <h3>Contact me at:</h3>
        <div className='icons-container'>
            <IconContext.Provider value={{ className: "icons" }}>
              <a className='icon-link' href="https://www.linkedin.com/in/jan-stuers/">
                <FaLinkedin/>
              </a>
              <a className='icon-link' href="https://www.instagram.com/januwe07/?hl=de">
                <FaInstagram/>
              </a>
              
            </IconContext.Provider>
        </div>
      </footer>
  )
}

export default AltContact;