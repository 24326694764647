import { useEffect } from 'react';

const Page1Effect2 = url => {
    useEffect(()=>{
        const canvas = document.getElementById('canvas');
        canvas.width = document.body.clientWidth;
        canvas.height = window.innerHeight;
        const ctx = canvas.getContext('2d');
        let hue = 100;
        let restriction = 0;
        let colorRestriction = 0;
        
        function Circle (x,y,r,c){
            this.x = x;
            this.y = y;
            this.r = r;
            this.c = 'hsl(' + hue + ', 100%, 50%';

            this.dx = Math.random() * (0.8-0.5)+0.5;
            this.dx *= 1;
            this.dy = Math.random() * (0.8-0.5)+0.5;
            this.dy *= 1;
            
            this.draw = function(){
                ctx.beginPath();
                ctx.fillStyle = this.c;
                ctx.arc(this.x, this.y, this.r, 0, Math.PI*2);
                ctx.fill();
            }
            this.update = function(){
                this.x += this.dx;
                this.y += this.dy;
            

                this.draw();
            }
        }

        const balls = [];
        

        function handleBalls(){
            for(let i = 0; i<balls.length; i++){
                balls[i].update();
                for (let j = i; j<balls.length; j++){
                    const dx = balls[i].x - balls[j].x;
                    const dy = balls[i].y - balls[j].y;
                    
                    const distance = Math.sqrt(dx*dx + dy*dy);
                    if (distance < 200){
                        ctx.beginPath();
                        ctx.strokeStyle = balls[i].c;
                        ctx.lineWidth = balls[i].size / 2;
                        ctx.moveTo(balls[i].x, balls[i].y);
                        ctx.lineTo(balls[j].x, balls[j].y);
                        ctx.stroke();
                    }
                }
                if(balls[i].x+balls[i].r > canvas.width+100|| balls[i].y+balls[i].r < -100){
                    balls.splice(i,1);
                    i--;
                    //console.log(balls.length);
                }
                
            }        
        }
        function spawn(n){
            for (let i=0;i<n;i++){
                let r = Math.floor(Math.random()*4)+2;
                let x = Math.random()* (canvas.width - (canvas.width/2))+(canvas.width/2)-200;
                let y = -100;
                let c = 'red';
                balls.push(new Circle(x,y,r,c));
            }
        }
        
        function animate(){
            ctx.clearRect(0,0,canvas.width,canvas.height);
            handleBalls();
            for (let i = 0; i<balls.length; i++){
                balls[i].update();
            }
            if(restriction === 15){
                spawn(1);
                restriction = 0; 
            }
            restriction++;

            if(colorRestriction === 5){
                //console.log(hue);
                hue++
                colorRestriction = 0;
            }
            colorRestriction++;


            requestAnimationFrame(animate);
        }
        window.addEventListener('resize',function(){
            canvas.width = document.body.clientWidth;
            canvas.height = window.innerHeight;
        })
        animate();
    },[url])
}
export default Page1Effect2;