import React from 'react';
import './Skills.css';


const Skills = (props) => {
    const title = props.title;
    const icon = props.icon;
    return(
        <>
            <div class="karte">
                <img src={icon}/>
                <p>{title}</p>
            </div>
        </>
    )
}
export default Skills;