import React from 'react';
import ResumeEntry from '../../components/resume/ResumeEntry'

import './page2.css';

const Page2 = () => {
    return(
        <>
            <div className = "page about-page">
                <div className= "container">
                    <div className = "left-side">
                        <div className ="left-side-bottom">
                        <h2>Education</h2>
                            <h3>Studied at</h3>
                            <ResumeEntry 
                                className="entry" 
                                name='Gymnasium Heißen' 
                                date='2011-2019' 
                                details='Abitur'
                            />
                            <br/>
                            <hr/>
                            <ResumeEntry
                                className="entry" 
                                name='Hochschule Ruhr West' 
                                date='2019-2024'
                                details='Applied computer science (B.Sc)'
                            />
                            <br/>
                            <hr/>
                            <ResumeEntry
                                className="entry" 
                                name='Ruhr Universität Bochum' 
                                date='since 2024'
                                details='IT-Security Networks & Systems (M.Sc)'
                            />
                        </div>
                    </div>
                    <div className = "mid-border"/>
                    <div className = "right-side">
                        <div className='right-top'/>
                        <div className='right-bottom'>
                            <h2>Experience</h2>
                            <h3>University projects</h3>
                            <ResumeEntry
                                className="entry" 
                                name='Bachelor thesis' 
                                date='2024'
                                details={'Conception and development of an interactive user interface for real-time data and configuration of a power quality meter'}
                            />
                            <br/>
                            <hr/>
                            <h3>Work experience</h3>
                            <ResumeEntry
                                className="entry" 
                                name='Siemens Energy AG' 
                                date='April-September 2023'
                                details='Software Developer for Process Automation in Order Management'
                            />
                            <br/>
                        </div>
                        
                    </div>
                </div>
                </div>
        </>
    )
}
export default Page2;